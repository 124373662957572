import { useContext, useEffect, useState } from "react";
import { FormState } from "../contexts/FormContext";
import { Form, Collapse, Descriptions, Radio } from 'antd';

const { Panel } = Collapse;
const { Item } = Descriptions;

const ReviewOrder = () => {
    const {state: { orderHold }, dispatch} = useContext(FormState);
    const [orders, setOrders] = useState([]);
    const defaultActive = orders.map((o, i) => i+1);

    useEffect(() => {
      const allOrders = []
        for(const bag in orderHold) {
          allOrders.push({
              bag,
              info: orderHold[bag]
          })
        }
        setOrders(allOrders)
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <div style={{ overflow: 'scroll', marginTop: '3em', height: '40em' }}>
              { 
                orders.length === 0 ? null :
                  <Collapse defaultActiveKey={defaultActive} >
                    {
                    orders.length === 0 ? null :
                      orders.map((order) => (
                        <Panel header={order.bag} key={order.bag.split` `[1]}>
                          <Descriptions title={order.info.type} layout='vertical' bordered>
                            {
                              Object.keys(order.info).map((field, i) => (
                                field === 'type' || field === 'notes' ? null :
                                <Item key={i} label={field}>
                                  {order.info[field]}
                                </Item>
                              ))
                            }
                            <Item label='Notes'>
                              {order.info.notes}
                            </Item>
                          </Descriptions>
                        </Panel>
                      ))
                    }
                  </Collapse>
              }
            </div>
            <div className='payment'>
              <h1>How Would This Customer Like To Pay?</h1>
              <Form.Item
                name='payment-type'
                rules={[{ required: true, message: 'Please choose a payment method!' }]}
              >
                <Radio.Group buttonStyle='solid' onChange={(e) => dispatch({type: 'payment', payload: e.target.value})}>
                  <Radio.Button value='cash'>
                    Cash
                  </Radio.Button>
                  <Radio.Button value='card'>
                    Request Card Payment
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
        </>
    )
}

export default ReviewOrder
