import { useContext, useEffect } from 'react';
import BagInfo from '../components/BagInfo';
import { Steps, Button, Input, InputNumber, Form as AntForm, Row, Col, Image } from 'antd';
import { UserOutlined, MailOutlined, ShoppingOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import ReviewOrder from '../components/ReviewOrder';
import { FormState } from '../contexts/FormContext'
import ProgressButtons from '../components/ProgressButtons';
import Logo from "../assets/images/HDW_Logo_BLK_Background.jpg"
import '../css/Form.css';

const { Step } = Steps;

const Form = () => {
  const [form] = AntForm.useForm()
  const {state: { steps, currentStep, formData, orderHold }, dispatch} = useContext(FormState);

  const createStepper = () => {
    const qty = Array(Number(formData.totalQuantity)).fill('')

    const allSteps = qty.map((n, index) => {
      return {
        title: `Bag ${index+1}`,
        content: <BagInfo form={form} />
      }
    })

    allSteps.push({
      title: 'Review',
      content: <ReviewOrder />
    })

    dispatch({type: 'steps', payload: allSteps})

    return dispatch({type: 'start'})
  }

  useEffect(() => {
    if (currentStep === -1){
      //
    } else if (currentStep === steps.length - 1) {
      //
    } else if (currentStep === steps.length) {
      //
    } else {
      const currentStepTitle = steps[currentStep].title
      form.setFieldsValue({
        type: orderHold[currentStepTitle].type,
        exterior: orderHold[currentStepTitle].exterior,
        interior: orderHold[currentStepTitle].interior,
        interiorColor: orderHold[currentStepTitle].interiorColor,
        notes: orderHold[currentStepTitle].notes,
      })
    }
    //eslint-disable-next-line
}, [currentStep])

  return (
    <>
      {currentStep === -1 ? (
        <Col style={{ height: '100%' }}>
          <Row justify='center'>
            <div style={{ marginTop: '1em' }}>
              <Image 
                width={400}
                src={Logo} 
              />
              <h1 style={{ marginTop: '.5em' }}>Hawaii Design Works Order Form</h1>
            </div>
          </Row>
          <Row justify='center'>
            <Col span={18}>
              <div className="steps-content">
                  <Row style={{ height: '100%' }} justify='center' align='middle'>
                    <Col style={{ height: '100%' }} span={18}>
                      <AntForm
                        layout='vertical'
                        align='middle'
                        style={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-evenly'
                        }}
                        onFinish={createStepper}
                      >
                        <AntForm.Item
                          label="Name:"
                          name="name"
                          rules={[{ required: true, message: 'Please Input Customer Name' }]}
                          onChange={(e) => dispatch({type: 'name', payload: e.target.value})}
                        >
                          <Input
                            size="large"
                            placeholder="Customer Full Name"
                            prefix={<UserOutlined />}
                          />
                        </AntForm.Item>
                        <AntForm.Item
                          label="Email:"
                          name="email"
                          rules={[{ required: true, message: 'Please Input Customer Email' }]}
                          justify='center'
                          >
                          <Input
                            size="large"
                            placeholder="Customer Email"
                            prefix={<MailOutlined />}
                            onChange={(e) => dispatch({type: 'email', payload: e.target.value})}
                            />
                        </AntForm.Item>
                        <AntForm.Item
                          label="Quantity:"
                          name="quantity"
                          rules={[{ required: true, message: 'Please Input Total Amount of Bags Being Ordered' }]}
                          justify='center'
                          >
                          <InputNumber
                            size="large"
                            placeholder="Total Bag Quantity"
                            prefix={<ShoppingOutlined />}
                            min={1}
                            style={{ width: '100%' }}
                            onChange={(value) => dispatch({type: 'qty', payload: value})}
                            />
                        </AntForm.Item>
                        <AntForm.Item
                          justify='center'
                        >
                          <Button type="primary" htmlType='submit'>
                            Next
                          </Button>
                        </AntForm.Item>
                      </AntForm>
                    </Col>
                  </Row>
              </div>
            </Col>
          </Row>
        </Col>
      ) : (
        <>
          <Row style={{ height: '100%' }}  justify='center'>
            <Col style={{ height: '100%' }}  span={20}>
              <Row style={{ marginTop: '1em' }} wrap>
                <Steps current={currentStep}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </Row>
              <div className="steps-content">
                <Row style={{ height: '100%' }} justify='center' align='middle'>
                  <Col style={{ height: '100%' }} span={18}>
                    { 
                      currentStep !== steps.length ?
                        <AntForm
                          layout='vertical'
                          style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly'
                          }}
                          initialValues={{
                            remember: true,
                          }}
                          form={form}
                          onFinish={() => dispatch({type: 'next'})}
                        >
                          {steps[currentStep].content}
                          <ProgressButtons />
                        </AntForm>
                        :
                        <div className='thank-you'>
                          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '25em' }} />
                          <h1>Thank You For Your Order!!!</h1>
                          <Button type="primary" htmlType='submit' onClick={() => window.location.reload()}>
                              Create New Order
                          </Button>
                        </div>
                    }
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Form
