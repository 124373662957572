import firebase from 'firebase/app';
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyBBaNBGNGlvwCsGYs17bB6fdOHg8Am_yvg",
    authDomain: "hawaii-design-works.firebaseapp.com",
    projectId: "hawaii-design-works",
    storageBucket: "hawaii-design-works.appspot.com",
    messagingSenderId: "700653810702",
    appId: "1:700653810702:web:0758c0f7218c7f451b785f",
    measurementId: "G-3ENCVG37RR"
};

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()

export { db }