import { createContext, useEffect, useReducer } from 'react';
import { db } from '../configs/firebaseConfig';

export const ProductState = createContext()

const initialState  = {
    products: {},
    forms: []
}

const productReducer = (state, { type, payload }) => {
    switch (type) {
        case 'addProduct':
            return {...state, products: {...state.products, [payload.id]: {name: payload.name, materials: payload.materials}} };
        case 'addForm':
            return {...state, forms: [...state.forms, payload]}
        default:
            break
    }
}

const ProductsContextV2 = ({ children }) => {
    const [productState, productDispatch] = useReducer(productReducer, initialState)

    const getData = async () => {
        const products = await db.collection('products').get()
        const forms = await db.collection('order-forms').get()

        products.forEach(product => {
            const id = product.id
            const { name, materials } = product.data()
            productDispatch({type: 'addProduct', payload: {id, name, materials}})
        })
        forms.forEach(form => {
            const id = form.id
            const { name, questions } = form.data()
            productDispatch({type: 'addForm', payload: {id, name, questions}})
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <ProductState.Provider value={{ productState }}>
            {children}
        </ProductState.Provider>
    )
}

export default ProductsContextV2
