import { createContext, useReducer } from 'react';

export const FormState = createContext()

const initialState = {
    steps: [],
    currentStep: -1,
    formData: {
        customerName: '',
        customerEmail: '',
        totalQuantity: '',
        paymentType: '',
        orders: [],
    },
    orderHold: {}
}

const formReducer = (state, { type, payload }) => {
    switch (type) {
        case 'start':
            return {...state, currentStep: state.currentStep = 0}
        case 'next':
            return {...state, currentStep: state.currentStep + 1};
        case 'prev':
            return {...state, currentStep: state.currentStep - 1};
        case 'steps':
            let orders = {}
            payload.forEach(order => {
                if (order.title === 'Review'){

                } else {
                    orders[order.title] = {
                        type: '',
                        notes: ''
                    }
                }
            })
            return {...state, steps: state.steps = payload, orderHold: orders};
        case 'reset':
            return {...state, orderHold: {...state.orderHold, [payload.bag]: {type: payload.value, notes: ''}}}
        case 'name':
            return {...state, formData: {...state.formData, customerName: state.formData.customerName = payload}};
        case 'email':
            return {...state, formData: {...state.formData, customerEmail: state.formData.customerEmail = payload}};
        case 'qty':
            return {...state, formData: {...state.formData, totalQuantity: state.formData.totalQuantity = payload}};
        case 'orders':
            return {...state, formData: {...state.formData, orders: state.formData.orders = payload }};
        case 'bag':
            const key = Object.keys(payload)[1]
            return {...state, orderHold: {...state.orderHold, [payload.bag]: {...state.orderHold[payload.bag], [key]: payload[String(key)]}}}
        case 'payment':
            return {...state, formData: {...state.formData, paymentType: state.formData.paymentType = payload }};
        default:
            break
    }
}

const FormContext = ({ children }) => {
    const [state, dispatch] = useReducer(formReducer, initialState)

    return (
        <FormState.Provider value={{state, dispatch}}>
            {children}
        </FormState.Provider>
    )
}

export default FormContext
