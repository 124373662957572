import { useContext } from 'react';
import { FormState } from '../contexts/FormContext'
import { Form, Input, Select, Image } from 'antd';
import { ProductState } from '../contexts/ProductsContext';

const { Option } = Select;
const { TextArea } = Input;

const BagInfo = ({ form }) => {
  const { productState: { products, forms }  } = useContext(ProductState)
  const {state: { steps, currentStep, orderHold }, dispatch} = useContext(FormState);
  const currentStepTitle = steps[currentStep].title;
  const formData = orderHold[currentStepTitle].type === '' ? null : forms.filter(form => form.name === orderHold[currentStepTitle].type)[0]

  const updateBag = (field, value) => {
    const info = {
        bag: currentStepTitle,
        [field]: value
    };
    dispatch({type: 'bag', payload: info});
  }

  const resetForm = (value) => {
    if(orderHold[currentStepTitle].type === '') {
      //
    } else {
      const fields = Object.keys(orderHold[currentStepTitle])
      fields.map(field => field === 'type' ? null : form.setFieldsValue({ [field]: '' }))
      const info = {
        bag: currentStepTitle,
        value
      }
      dispatch({type: 'reset', payload: info});
    }
  }

  return (
      <>
        <Form.Item
            label="Bag Type:"
            name="type"
            rules={[{ required: true, message: 'Please Select a Bag Type' }]}
          >
            <Select
              placeholder='--Please Select an Bag Type--'
              onChange={(value) => {
                updateBag('type', value)
                resetForm(value)
              }}
            >
              {
                Object.keys(products).map(product => <Option key={products[product].name} value={products[product].name}>{products[product].name}</Option>)
              }
            </Select>
          </Form.Item>
        {orderHold[currentStepTitle].type === '' ? null :
          <>
          {
            formData.questions.map((question, i) => (
              <div key={i}>
                { !question.triggerQuestion ?
                  <Form.Item
                    key={question.question}
                    label={`${question.question}:`}
                    name={question.question}
                    rules={[{required: true}]}
                    >
                      <Select
                        key={question.placeholder}
                        placeholder={question.placeholder}
                        onChange={(value) => updateBag(question.question, value)}
                      >
                        {
                          products[formData.id].materials[question.question].map(material => material.image ? <Option key={material.type} value={material.type}> <Image height={25} width={25} src={material.image} /> {material.type}</Option> : <Option key={material.type} value={material.color}>{material.type}</Option>)
                        }
                      </Select>
                  </Form.Item>
                  :
                  <>
                    <Form.Item
                      key={question.question}
                      label={`${question.question}:`}
                      name={question.question}
                      rules={[{required: true}]}
                      >
                        <Select
                          key={question.placeholder}
                          placeholder={question.placeholder}
                          onChange={(value) => {
                            updateBag(question.question, value)
                            form.setFieldsValue({
                              [question.triggerQuestion.question]: '',
                            })
                          }}
                        >
                          {
                            Object.keys(products[formData.id].materials[question.question]).map(lining => <Option key={lining} value={lining}> <Image height={25} width={25} src={products[formData.id].materials[question.question][lining][0].image} /> {lining}</Option>)
                          }
                        </Select>
                    </Form.Item>
                    {!orderHold[currentStepTitle][question.question]  ? null :
                      <Form.Item
                        key={question.triggerQuestion.question}
                        label={`${question.triggerQuestion.question}:`}
                        name={question.triggerQuestion.question}
                        rules={[{required: true}]}
                      >
                        <Select
                          key={question.triggerQuestion.placeholder}
                          placeholder={question.triggerQuestion.placeholder}
                          onChange={(value) => updateBag(question.triggerQuestion.question, value)}
                        >
                          {
                            products[formData.id].materials[question.question][orderHold[currentStepTitle][question.question]].map(trigger => <Option key={trigger.color} value={trigger.color}> <Image height={25} width={25} src={trigger.image}/> {trigger.color}</Option>)
                          }
                        </Select>
                    </Form.Item>
                    } 
                  </>
                }
              </div>
            ))
          }

          <Form.Item
            label='Notes (Optional):'
            name='notes'
            rules={[{required: false}]}
          >
              <TextArea
                placeholder='Leave any additional notes about this bag if required.'
                onChange={(e) => updateBag('notes', e.target.value)}
              />
          </Form.Item>
        </>
        }
      </>
  )
}

export default BagInfo
