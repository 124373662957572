import Form from './views/Form';
import FormContext from './contexts/FormContext';
import ProductsContext from './contexts/ProductsContext';


function App() {
  return (
    <FormContext>
      <ProductsContext>
        <Form />
      </ProductsContext>
    </FormContext>
  )
}

export default App;
