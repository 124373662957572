
import { useContext } from 'react';
import { Form, Button, message } from 'antd';
import { db } from '../configs/firebaseConfig';
import { FormState } from '../contexts/FormContext';
import { camelize } from '../helpers/StringMethods'

const ProgressButtons = () => {
    const {state: { currentStep, steps, orderHold, formData }, dispatch} = useContext(FormState);

    const submitOrder = async () => {
        const unformattedOrders = [];
        for(const bag in orderHold) {
            unformattedOrders.push(orderHold[bag])
        }

        const orders = unformattedOrders.map(order => {
            for(const peice in order) {
                if(peice.includes(' ')) {
                    delete Object.assign(order, {[camelize(peice)]: order[peice]})[peice]
                } else {
                    //
                }
            }

            return order
        })

        await dispatch({type: 'orders', payload: orders})
        
        await db.collection('orders').doc(`${formData.customerName.toLowerCase().split` `[1]}-${Date.now()}`).set({
            ...formData,
            status: 'in queue'
        })

        dispatch({type: 'next'})
        message.success("Processing complete!")
    }

    return (
        <Form.Item className="steps-action">
            {currentStep > 0 && (
            <Button
                style={{ margin: "0 8px" }}
                onClick={() => dispatch({type: 'prev'})}
            >
                Previous
            </Button>
            )}
            {currentStep < steps.length - 1 && (
            <Button type="primary" htmlType='submit'>
                Next
            </Button>
            )}
            {currentStep === steps.length - 1 && (
            <Button
                type="primary"
                disabled={formData.paymentType === '' ? true : false}
                onClick={() => submitOrder()}
            >
                Done
            </Button>
            )}
        </Form.Item>
    )
}

export default ProgressButtons
